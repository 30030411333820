import styles from "./../styles/404.module.scss"
import React from "react"
import Layout from "../components/layout/layout"
import { Link } from "gatsby"
import withAppContext from "../contexts/app.context"

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div
        className={`row d-flex flex-column align-items-center ${styles.row}`}>
        <h1 className={styles.title}>Page not found</h1>
        <p className={styles.description}>
          We're sorry, the page you requested could not be found. <br />
          Please go back to the homepage or contact us at{" "}
          <a
            aria-label="Mail to support"
            className={styles.link}
            href="mailto:support@ves.io">
            support@ves.io
          </a>
        </p>
        <Link
          aria-label="Homepage"
          className={`btn btn-primary ${styles.btn}`}
          to={"/"}>
          Homepage
        </Link>
      </div>
    </div>
  </Layout>
)

export default withAppContext(NotFoundPage)
